<template>
  <v-sheet class="montserrat-font">
    <header-view></header-view>
    <mape-introduction></mape-introduction>
    <about-us></about-us>
    <unique-view></unique-view>
    <quantum-leap></quantum-leap>
    <platform-details></platform-details>
    <contact-us></contact-us>
    <footer-view></footer-view>
  </v-sheet>
</template>

<script>
import HeaderView from "./HeaderView.vue";
import MapeIntroduction from "./MapeIntroduction.vue";
import AboutUs from "./AboutUs.vue";
import UniqueView from "./UniqueView.vue";
import QuantumLeap from "./QuantumLeap.vue";
import PlatformDetails from "./PlatformDetails.vue";
import ContactUs from "./ContactUs.vue";
import FooterView from "./FooterView.vue";
export default {
  name: "HomeView",
  components: { HeaderView, MapeIntroduction, AboutUs, UniqueView, QuantumLeap, PlatformDetails, ContactUs, FooterView },
  data: () => ({}),
  computed: {},
  watch: {},
  created() {},

  mounted() {},
  methods: {},
};
</script>
<style scoped>
.montserrat-font {
  font-family: "montserrat", "Roboto", serif !important;
}
</style>
