const Constant = {};

Constant.install = function (Vue, options) {
  // 动态导入所有枚举文件，最后组成结构如下：
  /**
   ***{module1:{k1:v1,k2:v2}, module2:{k1:v1,k2:v2}}
   **/
  const loadLocaleEnums = function () {
    const locales = require.context("./modules", true, /[A-Za-z0-9-_,\s]+\.js$/i);
    const enumInfo = {};
    locales.keys().forEach((key) => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i);
      if (matched && matched.length > 1) {
        const locale = matched[1];
        enumInfo[locale] = locales(key).default;
      }
    });

    return enumInfo;
  };

  const cnstInfo = options || loadLocaleEnums() || {};
  const Enum = {};

  // Enum.getTextValueList = function (enumName) {
  //   console.log(enumName);
  //   return null;
  // };

  Enum.getEnumItem = function (moduleName, enumName) {
    // eslint-disable-next-line no-prototype-builtins
    if (!cnstInfo.hasOwnProperty(moduleName)) {
      return "";
    }

    const moduleItem = cnstInfo[moduleName];
    // eslint-disable-next-line no-prototype-builtins
    if (!moduleItem.hasOwnProperty(enumName)) {
      return "";
    }

    return moduleItem[enumName];
  };

  /***
   * moduleName 模块名，也即是modules下的各文件名
   * enumName 枚举名
   * enumValue 枚举值
   */
  Enum.getTextByValue = function (moduleName, enumName, enumValue) {
    // eslint-disable-next-line no-prototype-builtins
    if (!cnstInfo.hasOwnProperty(moduleName)) {
      return "";
    }

    const moduleItem = cnstInfo[moduleName];
    // eslint-disable-next-line no-prototype-builtins
    if (!moduleItem.hasOwnProperty(enumName)) {
      return "";
    }

    const constItem = moduleItem[enumName];
    for (let item in constItem) {
      if (constItem[item].value === enumValue) {
        return constItem[item].text;
      }
    }
  };

  /***
   * moduleName 模块名，也即是modules下的各文件名
   * enumName 枚举名
   * rclk
   */
  Enum.getTextValueMap = function (moduleName, enumName) {
    // eslint-disable-next-line no-prototype-builtins
    if (!cnstInfo.hasOwnProperty(moduleName)) {
      return {};
    }

    const moduleItem = cnstInfo[moduleName];
    // eslint-disable-next-line no-prototype-builtins
    if (!moduleItem.hasOwnProperty(enumName)) {
      return {};
    }

    const result = {};
    let constItem = moduleItem[enumName];
    for (let item in constItem) {
      result[constItem[item].text] = constItem[item].value;
    }

    return result;
  };

  Enum.getValueTextMap = function (moduleName, enumName) {
    // eslint-disable-next-line no-prototype-builtins
    if (!cnstInfo.hasOwnProperty(moduleName)) {
      return {};
    }

    const moduleItem = cnstInfo[moduleName];
    // eslint-disable-next-line no-prototype-builtins
    if (!moduleItem.hasOwnProperty(enumName)) {
      return {};
    }

    const result = {};
    let constItem = moduleItem[enumName];
    for (let item in constItem) {
      result[constItem[item].value] = constItem[item].text;
    }
    return result;
  };

  Vue.prototype.$enum = Enum;
};

export default Constant;
