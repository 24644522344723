import http from "@/utils/http/index.js";

export default {
  uploadData: (param) => {
    var headers = {
      "Content-Type": "multipart/form-data",
    };
    return http.$p("/data/management/upload-data", param, headers);
  },
  uploadDataConfirm: (param) => {
    return http.$p("/data/management/upload-data-confirm", param);
  },

  uploadStatusRecordList: (param) => {
    return http.$p("/data/management/status-record-list", param);
  },

  datasetList: (param) => {
    return http.$p("/data/management/datasetList", param);
  },

  dataset: (param) => {
    return http.$p("/data/management/dataset", param);
  },

  uploadDataSet: (param) => {
    let headers = {
      "Content-Type": "multipart/form-data",
    };
    return http.$p("/data/management/upload-dataset", param, headers);
  },

  dataCellList: (param) => {
    return http.$p("/data/management/data-cell-list", param);
  },

  modifyDataCellList: (param) => {
    return http.$p("/data/management/modify-data-cell-list", param);
  },

  removeDataCellList: (param) => {
    return http.$p("/data/management/remove-data-cell-list", param);
  },

  timePeriodList: (param) => {
    return http.$p("/data/management/time-period-list", param);
  },
};
