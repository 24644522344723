const API = {};
API.install = (Vue) => {
  // 动态导入所有子目录modules下的API文件：
  const locales = require.context("./modules", true, /[A-Za-z0-9-_,\s]+\.js$/i);
  const apis = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      apis[locale] = locales(key).default;
    }
  });

  // 所有API挂载到Vue原型链上
  Vue.prototype.$api = apis;
};

export default API;
