<template>
  <v-dialog v-model="show" width="40%" max-width="500" persistent>
    <v-card>
      <v-card-title class="headline white--text" :class="color" primary-title>{{ title }}</v-card-title>
      <v-card-text class="px-4 py-8">
        <!-- <v-avatar :color="color">
          <v-icon v-if="color === 'warning'" color="white" size="32"
            >mdi-alert-box-outline</v-icon
          >
          <v-icon v-else-if="color === 'info'" color="white" size="32">
            mdi-information-outline</v-icon
          >
          <v-icon v-else color="white" size="32"
            >mdi-checkbox-marked-circle</v-icon
          >
        </v-avatar> -->
        <span class="ml-2">{{ message }}</span>
      </v-card-text>
      <!-- <v-divider></v-divider> -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="confirm()" depressed>AGREE</v-btn>
        <v-btn @click="cancel()" depressed>CANCEL</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { VDialog, VCard, VSpacer } from "vuetify/lib";
export default {
  name: "confirm",
  components: { VDialog, VCard, VSpacer },
  data() {
    return {
      show: false,
      title: "",
      message: "",
      color: "",
    };
  },
  methods: {
    // cancel() {
    //   this.show = false
    // },
    // confirm() {
    //   this.show = false
    // }
  },
};
</script>
<style scoped></style>
