import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import user from "./modules/user";

import Contextmenu from "vue-contextmenujs/dist/contextmenu.umd";

Vue.use(Contextmenu);
Vue.use(Vuex);

export default new Vuex.Store({
  modules: { user },
  plugins: [createPersistedState()],
});
