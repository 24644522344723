import http from "@/utils/http/index.js";

export default {
  userPage: (param) => {
    return new Promise((resolve, reject) => {
      http
        .$p("/sys-user/page", param)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  userDetail: (param) => {
    return new Promise((resolve, reject) => {
      http
        .$p("/sys-user/detail", param)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  userAdd: (param) => {
    return new Promise((resolve, reject) => {
      http
        .$p("/sys-user/add", param)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  userModInfo: (param) => {
    return new Promise((resolve, reject) => {
      http
        .$p("/sys-user/mod-info", param)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  userChgStat: (param) => {
    return new Promise((resolve, reject) => {
      http
        .$p("/sys-user/chg-stat", param)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  userRstPw: (param) => {
    return new Promise((resolve, reject) => {
      http
        .$p("/sys-user/rst-pw", param)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  userDelete: (param) => {
    return new Promise((resolve, reject) => {
      http
        .$p("/sys-user/delete", param)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
