// import Vuetify from "vuetify/lib";
import vuetify from "@/plugins/vuetify";
import ConfirmBox from "./Confirm";

const Confirm = {};

Confirm.install = (Vue) => {
  const ConfirmBoxContructor = Vue.extend(ConfirmBox);
  const instance = new ConfirmBoxContructor();
  // instance.$vuetify = new Vuetify().framework;
  instance.$vuetify = vuetify.framework;
  instance.$mount(document.createElement("p"));
  document.body.appendChild(instance.$el);

  const fire = (type, title, message) => {
    return new Promise((resolve) => {
      instance.title = title;
      instance.message = message;
      instance.show = true;
      instance.color = type;
      instance.cancel = () => {
        resolve(false);
        instance.show = false;
      };
      instance.confirm = () => {
        resolve(true);
        instance.show = false;
      };
    });
  };

  Vue.prototype.$confirm = {};

  Vue.prototype.$confirm.success = (title, message) => {
    return fire("success", title, message);
  };

  Vue.prototype.$confirm.info = (title, message) => {
    return fire("info", title, message);
  };

  Vue.prototype.$confirm.warning = (title, message) => {
    return fire("warning", title, message);
  };
};

export default Confirm;
