import Vue from "vue";
import VueI18n from "vue-i18n";

// 按需引入 vuetify 翻译文件
import en from "vuetify/lib/locale/en";
import zhHans from "vuetify/lib/locale/zh-Hans";
import zhHant from "vuetify/lib/locale/zh-Hant";

Vue.use(VueI18n);

// 构建多语言 vuetify 翻译对象，以便下面动态合并当前工程自定义对应语言文件
const vuetifyLocale = {
  en: { $vuetify: en },
  zhHans: { $vuetify: zhHans },
  zhHant: { $vuetify: zhHant },
};

// 动态导入翻译文件
function loadLocaleMessages() {
  const locales = require.context("./lang", true, /[A-Za-z0-9-_,\s]+\.js$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = Object.assign({}, locales(key).default, vuetifyLocale[locale]);
    }
  });

  return messages;
}

// 创建vue-i18n实例并设置语言选项
export default new VueI18n({
  locale: "en", // 默认语言
  messages: loadLocaleMessages(), // 翻译内容
});
