import "@mdi/font/css/materialdesignicons.css";

import Vue from "vue";
// import Vuetify from "vuetify/lib/framework";
import Vuetify from "vuetify/lib";
Vuetify.config.silent = true;

import i18n from "../i18n/index.js";

Vue.use(Vuetify);

const opts = {
  lang: {
    t: (key, ...params) => i18n.t(key, params), // 设置Vuetify使用vue-i18n进行翻译
  },
  theme: {
    dark: false, // 初始模式（明亮/暗黑）
    themes: {
      light: {
        primary: "#fcb017", // 默认主题色
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00",
      },
      dark: {
        // primary: "#2196f3",
        primary: "#fcb017",
      },
    },
  },
};

export default new Vuetify(opts);
