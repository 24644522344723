<template>
  <v-row no-gutters>
    <v-col cols="12" md="5" sm="12">
      <div
        class="d-flex flex-column justify-center px-4 px-md-10 text-container"
        :class="$vuetify.breakpoint.name === 'md' || $vuetify.breakpoint.name === 'lg' || $vuetify.breakpoint.name === 'xl' ? 'full-height' : ''"
        style="position: relative"
      >
        <div class="hidden-sm-and-down" style="position: absolute; left: -20px; top: 100px; z-index: 0">
          <v-img width="200" src="@/assets/img/mape/png/bananer-left.png" contain></v-img>
        </div>
        <div style="z-index: 2">
          <p class="text-h4 text-sm-h3 text-md-h2 font-weight-bold montserrat-font title-color">Marketing Measurement Platform</p>
          <p class="d-none d-md-block text-h5 darken-2 my-6 montserrat-font" style="color: #fcb017">Unified - Automated - Measurement</p>
          <p class="d-md-none text-h6 text-sm-h5 darken-2 my-6 montserrat-font" style="color: #fcb017">Unified-Automated-Measurement</p>
          <p class="grey--text lighten-2">
            We are marketingApe, a marketing performance measurement company. By using advanced data driven AI analytics, we help our clients to understand, and
            improve, their marketing performance: ROI, ROAS, cost per click, etc.
          </p>
        </div>
        <!-- <v-btn class="d-none d-md-block my-4" width="200" elevation="2" @click="$vuetify.goTo('#contact-us')" large rounded dark>LEARN MORE </v-btn> -->
      </div>
    </v-col>
    <v-col cols="12" md="7" sm="12">
      <div
        class="d-flex flex-column align-center justify-center py-md-16 img-container"
        :class="$vuetify.breakpoint.name === 'md' || $vuetify.breakpoint.name === 'lg' || $vuetify.breakpoint.name === 'xl' ? 'full-height' : ''"
      >
        <img class="dash-img d-block pt-16" max-height="50%" width="70%" src="@/assets/img/mape/svg/dashboard.svg" />
        <v-btn class="d-md-none my-16" width="200" elevation="2" @click="$vuetify.goTo('#contact-us')" large rounded dark>LEARN MORE </v-btn>
      </div>
    </v-col>
  </v-row>
</template>
<script>
export default {};
</script>
<style scoped>
.full-height {
  height: 65vh;
}
.montserrat-font {
  font-family: "montserrat", "Roboto", serif !important;
}

.title-color {
  color: rgb(0, 0, 0, 0.6) !important;
}

.text-container {
  padding-top: 150px !important;
}

.img-container {
  background-image: url(@/assets/img/mape/png/yellow-cornner.png);
  background-position: left;
  background-size: cover;
}

.corner-img {
  z-index: 1;
}

.dash-img {
  /* z-index: 2; */
  /* padding-top: 150px; */
  /* padding-bottom: 100px; */
}
</style>
