<template>
  <v-snackbar v-model="show" :color="color" :timeout="duration" multi-line right top>
    <div>
      {{ message }}
    </div>
    <div class="solution-des">
      <strong>{{ solution }}</strong>
    </div>
    <template v-slot:action="{}">
      <v-btn @click="show = false" text>Close</v-btn>
    </template>
  </v-snackbar>
</template>
<script>
import { VSnackbar, VBtn } from "vuetify/lib";
export default {
  name: "toast",
  components: { VSnackbar, VBtn },
  data() {
    return {
      show: false,
      message: "",
      solution: "",
      color: "#2196F3",
      duration: 3000,
    };
  },
};
</script>
<style scoped>
.solution-des {
  padding-top: 15px;
  white-space: inherit;
  word-break: break-all;
}
</style>
