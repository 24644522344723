import http from "@/utils/http/index.js";

export default {
  avmData: (param) => {
    return http.$p("/data/view/avm-data", param);
  },
  avmModelList: () => {
    return http.$p("/data/view/avm-model-list", {});
  },
  decomData: (param) => {
    return http.$p("/data/view/decom-data", param);
  },
  decomModelList: () => {
    return http.$p("/data/view/decom-model-activity-list", {});
  },
  importData: (param) => {
    var headers = {
      "Content-Type": "multipart/form-data",
    };
    return http.$p("/data/view/import", param, headers);
  },

  facebookSubmitAuth: (param) => {
    return http.$p("/ads/facebook/auth/submit", param);
  },
  addConnector: (param) => {
    return http.$p("/connector/add", param);
  },
  connectorList: () => {
    return http.$p("/connector/list", {});
  },
  googleAuthConfig: () => {
    return http.$p("/ads/google/auth/config", {});
  },
  connectorAccountList: (param) => {
    return http.$p("/connector/account-list", param);
  },
};
