export default {
  app: {
    home: "Home Page",
    prediction: "Prediction",
    dataAnalysis: "Data Analysis",
    login: "Log In",
    joinUs: "Join Us",
    aboutUs: "About Us",
    platform: "Platform",
    contactUs: "Contact Us",
  },
  homeView: {
    declaration:
      "All contents on this website and its app are for informational purposes only and do not involve any money and gambling and shall not be relied on as such. For details, please refer to the",
    rules: "Terms and Conditions of Use",
    privacyPolicy: "Privacy Policy",
    companyName: "Kreaton Technology Limited",
  },
  predictionView: {},
  dataAnalysisView: {},
};
