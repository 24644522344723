<template>
  <div class="d-none d-md-block quantum-lean-container wave-bg">
    <div class="d-flex align-top justify-center" style="position: relative; height: 1000px">
      <p class="text-h4 text-sm-h3 text-md-h2 font-weight-bold mt-10 title-color">Quantum Leap</p>
      <v-img class="rocket" width="500" src="@/assets/img/mape/svg/rocket.svg" contain></v-img>
    </div>

    <div class="d-flex" style="height: 200px; width: 100%; margin-top: -550px; position: relative; z-index: 4">
      <img class="ml-16" src="@/assets/img/mape/svg/car.svg" />
      <v-spacer></v-spacer>

      <img class="mb-n4" src="@/assets/img/mape/svg/flag.svg" />
    </div>

    <!--  -->
    <div class="d-flex align-center justify-center py-16 car-bg" style="position: relative; display: block">
      <v-spacer></v-spacer>

      <div class="d-flex mx-n2">
        <v-img src="@/assets/img/mape/png/arrow-left.png"></v-img>
        <div class="d-flex align-center justify-center arrow-content">
          <div>
            <div class="text-h7 text-center">Data</div>
            <div class="text-h7 text-center">Collection</div>
          </div>
        </div>
        <v-img src="@/assets/img/mape/png/arrow-right.png"></v-img>
      </div>

      <div class="d-flex mx-n2">
        <v-img src="@/assets/img/mape/png/arrow-left.png"></v-img>
        <div class="d-flex align-center justify-center arrow-content">
          <div>
            <div class="text-h7 text-center">Basic</div>
            <div class="text-h7 text-center">Reporting</div>
          </div>
        </div>
        <v-img src="@/assets/img/mape/png/arrow-right.png"></v-img>
      </div>

      <div class="d-flex mx-n2">
        <v-img src="@/assets/img/mape/png/arrow-left.png"></v-img>
        <div class="d-flex align-center justify-center arrow-content">
          <div>
            <div class="text-h7 text-center">Advanced</div>
            <div class="text-h7 text-center">Analytics</div>
          </div>
        </div>
        <v-img src="@/assets/img/mape/png/arrow-right.png"></v-img>
      </div>

      <div class="d-flex mx-n2">
        <v-img src="@/assets/img/mape/png/arrow-left.png"></v-img>
        <div class="d-flex align-center justify-center arrow-content">
          <div>
            <div class="text-h7 text-center">Predictive</div>
            <div class="text-h7 text-center">Modeling</div>
          </div>
        </div>
        <v-img src="@/assets/img/mape/png/arrow-right.png"></v-img>
      </div>

      <div class="d-flex mx-n2">
        <v-img src="@/assets/img/mape/png/arrow-left.png"></v-img>
        <div class="d-flex align-center justify-center arrow-content">
          <div>
            <div class="text-h7 text-center">Multi-Touch</div>
            <div class="text-h7 text-center">Attribution</div>
          </div>
        </div>
        <v-img src="@/assets/img/mape/png/arrow-right.png"></v-img>
      </div>

      <div class="d-flex mx-n2">
        <v-img src="@/assets/img/mape/png/arrow-left.png"></v-img>
        <div class="d-flex align-center justify-center arrow-content">
          <div>
            <div class="text-h7 text-center py-0">Marketing</div>
            <div class="text-center">Mix</div>
            <div class="text-h7 text-center py-0">Modeling</div>
          </div>
        </div>
        <v-img src="@/assets/img/mape/png/arrow-right.png"></v-img>
      </div>

      <div class="d-flex mx-n2">
        <v-img src="@/assets/img/mape/png/arrow-left.png"></v-img>
        <div class="d-flex align-center justify-center arrow-content">
          <div>
            <div class="text-h7 text-center">Insights</div>
            <div class="text-h7 text-center">& Actions</div>
          </div>
        </div>
        <v-img src="@/assets/img/mape/png/arrow-right.png"></v-img>
      </div>

      <v-spacer></v-spacer>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.montserrat-font {
  font-family: "montserrat", "Roboto", serif !important;
}

.title-color {
  color: rgb(0, 0, 0, 0.6) !important;
}
.quantum-lean-container {
  z-index: 0;
  position: relative;
  top: -300px;
  margin-bottom: -300px;
}
.wave-bg {
  /* position: absolute; */
  z-index: 0;
  width: 100%;
  min-height: 1000px;
  background-image: url(@/assets/img/mape/png/wave-bg.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  padding-top: 300px;
}
.car-bg {
  /* left: 0; */
  /* top: -400px; */
  position: absolute;
  bottom: 0;
  width: 100%;
  background-image: url(@/assets/img/mape/svg/car-bg.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  /* background-size: cover; */
}
.rocket {
  z-index: 3;
  position: absolute;
  bottom: 45%;
  right: 64px;
}
.arrow-content {
  height: 84px;
  padding: 0 2px 0 14px;
  background-color: #efb243;
  border: none;
}
</style>
