<template>
  <v-sheet class="contactus-bg">
    <v-row class="px-4 px-sm-8 px-md-10 py-10" no-gutters justify="space-around">
      <v-col cols="12" md="6" xl="5" align-self="center" class="mb-10 mb-md-0 px-md-8">
        <div class="text-h4 text-sm-h3 text-md-h2 white--text text-center text-md-start font-weight-bold montserrat-font line-height-1_5">
          Are you ready to make your marketing analysis to the next level?
        </div>
      </v-col>
      <v-col cols="12" md="6" xl="5" align-self="center" class="text-center white--text px-md-8">
        <v-form ref="form" id="contact-us" v-model="form.validation.result">
          <v-row dense>
            <v-col cols="12">
              <div class="text-start">Name</div>
              <v-text-field background-color="white" outlined hide-details="auto" class="rounded-xxl" v-model="form.input.name"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <div class="text-start">Email</div>
              <v-text-field
                background-color="white"
                outlined
                hide-details="auto"
                class="rounded-xxl"
                v-model="form.input.email"
                :rules="form.validation.emailRule"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <div class="text-start">Phone Number</div>
              <v-text-field background-color="white" outlined hide-details="auto" class="rounded-xxl" v-model="form.input.phone"></v-text-field>
            </v-col>
            <v-col cols="12">
              <div class="text-start">Message</div>
              <v-textarea background-color="white" rows="5" outlined hide-details="auto" class="rounded-xxl" v-model="form.input.message"></v-textarea>
            </v-col>
            <v-col cols="12" align="start">
              <div id="recaptcha"></div>
              <v-btn color="error" :loading="loading" :disabled="loading" @click="submit" fab x-large rounded dark block class="d-flex d-sm-none mt-2"
                >Submit</v-btn
              >
              <v-btn color="error" :loading="loading" :disabled="loading" @click="submit" fab x-large rounded dark width="200" class="d-none d-sm-flex mt-2"
                >Submit</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      sitekey: "6Lf0zGApAAAAAEnUcdW7pQnJ_0aLnhWhrFlzo2vd", //"6Ld24FkpAAAAAL64aFKUZihFG5d_j43kIv3qFHph",
      googleLoaded: false,
      form: {
        input: {
          name: "", // 名称
          email: "", // 邮箱
          phone: "", // 电话号码
          vcode: "", // 验证码
          message: "", // 信息
        },
        validation: {
          result: true,
          emailRule: [(v) => !!v || "Email is required", (v) => /.+@.+\..+/.test(v) || "Email must be valid"],
          vcodeRule: [(v) => !!v || "Valid code is required"],
        },
      },
    };
  },
  created() {
    this.getSiteSecret();
  },
  computed: {},
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (!this.form.input.vcode) {
          this.$message.warning("Tip", "Please verify I'm not a robot.");
          this.loading = false;
          return;
        }

        this.form.input.email = this.form.input.email.trim();

        // 发起请求
        this.$api.customer
          .add(this.form.input)
          .then((res) => {
            // 判定状态
            if (res.code === "IAAO-0000") {
              this.$message.success("Thank You", "The form was submitted successfully.").then((res) => {
                if (res) {
                  // 清空表单
                  this.$refs.form.reset();
                }
              });
            } else {
              this.$message.error("Sorry", "Interface error occurred.");
            }
            this.loading = false;
          })
          .catch((err) => {
            console.error(err);
            this.$message.error("Sorry", "Interface error occurred, message: " + err.message);
            this.loading = false;
          });
      }
    },
    getSiteSecret() {
      this.$api.customer
        .siteSecret()
        .then((res) => {
          if (res.code === "IAAO-0000") {
            this.sitekey = res.data ? res.data : this.sitekey;
            document.addEventListener("google-recaptcha-event", this.loaded);
            this.loaded();
          } else {
            this.$toast.error(res.message + ", " + res.solution);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    resetForm() {
      this.$refs.form.reset();
    },
    setVCode(token) {
      this.form.input.vcode = token;
    },
    clearVCode() {
      this.form.input.vcode = "";
    },
    loaded() {
      if (!this.googleLoaded && window.grecaptcha && window.grecaptcha.render) {
        window.grecaptcha.render("recaptcha", {
          sitekey: this.sitekey,
          callback: this.setVCode,
          "expired-callback": this.clearVCode,
          //size: "compact",
        });

        this.googleLoaded = true;
      }
    },
  },
  mounted() {},
  beforeDestroy() {
    document.removeEventListener("google-recaptcha-event", this.loaded);
  },
};
</script>
<style scoped>
.montserrat-font {
  font-family: "montserrat", "Roboto", serif !important;
}
.line-height-1_5 {
  line-height: 1.5 !important;
}
.contactus-bg {
  background-image: url(@/assets/img/mape/png/contact-us-bg.png);
  background-position: center;
  background-size: cover;
}
</style>
