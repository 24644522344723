import http from "@/utils/http/index.js";

export default {
  userPage: (param) => {
    return http.$p("/mgmt/user/page", param);
  },
  userDetail: (param) => {
    return http.$p("/mgmt/user/detail", param);
  },
  findSysUser: (param) => {
    return http.$p("/mgmt/user/find-sys-user", param);
  },
  userAdd: (param) => {
    return http.$p("/mgmt/user/add", param);
  },
  userModInfo: (param) => {
    return http.$p("/mgmt/user/mod-info", param);
  },
  userChgStat: (param) => {
    return http.$p("/mgmt/user/chg-stat", param);
  },
  userDelete: (param) => {
    return http.$p("/mgmt/user/delete", param);
  },
  userListRoles: (param) => {
    return http.$p("/mgmt/user/list-roles", param);
  },
  userBindRoles: (param) => {
    return http.$p("/mgmt/user/bind-roles", param);
  },

  roleList: (param) => {
    return new Promise((resolve, reject) => {
      http
        .$p("/mgmt/role/list", param)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  rolePage: (param) => {
    return new Promise((resolve, reject) => {
      http
        .$p("/mgmt/role/page", param)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  roleDetail: (param) => {
    return new Promise((resolve, reject) => {
      http
        .$p("/mgmt/role/detail", param)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  roleAdd: (param) => {
    return new Promise((resolve, reject) => {
      http
        .$p("/mgmt/role/add", param)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  roleModify: (param) => {
    return new Promise((resolve, reject) => {
      http
        .$p("/mgmt/role/modify", param)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  roleDelete: (param) => {
    return new Promise((resolve, reject) => {
      http
        .$p("/mgmt/role/delete", param)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  roleListPerms: (param) => {
    return new Promise((resolve, reject) => {
      http
        .$p("/mgmt/role/list-perms", param)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  roleBindPerms: (param) => {
    return new Promise((resolve, reject) => {
      http
        .$p("/mgmt/role/bind-perms", param)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  permsTree: () => {
    return http.$p("/permission/tree-curr-tenant");
  },
};
