/**
 * 预测页面
 */
export default {
  raceRoundItems: [
    { text: "第一场", value: 1 },
    { text: "第二场", value: 2 },
    { text: "第三场", value: 3 },
    { text: "第四场", value: 4 },
    { text: "第五场", value: 5 },
    { text: "第六场", value: 6 },
    { text: "第七场", value: 7 },
    { text: "第八场", value: 8 },
    { text: "第九场", value: 9 },
    { text: "第十场", value: 10 },
    { text: "第十一场", value: 11 },
  ],
};
