import Vuetify from "vuetify/lib";
import MessageBox from "./Message";

const Message = {};

Message.install = (Vue) => {
  const MessageBoxContructor = Vue.extend(MessageBox);
  const instance = new MessageBoxContructor();
  instance.$vuetify = new Vuetify().framework;
  instance.$mount(document.createElement("msg"));
  document.body.appendChild(instance.$el);

  const fire = (type, title, message) => {
    return new Promise((resolve) => {
      instance.show = true;
      instance.title = title;
      instance.subtitle = message;
      instance.color = type;
      instance.confirm = () => {
        resolve(true);
        instance.show = false;
      };
    });
  };

  Vue.prototype.$message = {};

  Vue.prototype.$message.success = (title, message) => {
    return fire("success", title, message);
  };

  Vue.prototype.$message.info = (title, message) => {
    return fire("info", title, message);
  };

  Vue.prototype.$message.warning = (title, message) => {
    return fire("warning", title, message);
  };

  Vue.prototype.$message.error = (title, message) => {
    return fire("error", title, message);
  };
};

export default Message;
