/**
 * 数据分析页面
 */
export default {
  // 档位
  gearItems: [
    { text: "档位一", value: 1 },
    { text: "档位二", value: 2 },
    { text: "档位三", value: 3 },
    { text: "档位四", value: 4 },
    { text: "档位五", value: 5 },
    { text: "档位六", value: 6 },
    { text: "档位七", value: 7 },
    { text: "档位八", value: 8 },
    { text: "档位九", value: 9 },
    { text: "档位十", value: 10 },
    { text: "档位十一", value: 11 },
    { text: "档位十二", value: 12 },
    { text: "档位十三", value: 13 },
    { text: "档位十四", value: 14 },
    { text: "档位十五", value: 15 },
  ],
  // 班次
  shiftItems: [
    { text: "0", value: 0 },
    { text: "1", value: 1 },
    { text: "2", value: 2 },
    { text: "3", value: 3 },
    { text: "4", value: 4 },
    { text: "5", value: 5 },
    { text: "6", value: 6 },
    { text: "7", value: 7 },
    { text: "8", value: 8 },
    { text: "9", value: 9 },
  ],
  // 赛道
  trackItems: [
    { text: "A赛道", value: 1 },
    { text: "B赛道", value: 2 },
    { text: "C赛道", value: 3 },
    { text: "W赛道", value: 4 },
  ],
  // 赛道质地
  geologyItems: [
    { text: "草地", value: 1 },
    { text: "全天侯", value: 2 },
  ],
};
