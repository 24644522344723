<template>
  <v-app-bar class="montserrat-font" height="100" :color="darColor" v-scroll="onScroll" elevate-on-scroll app>
    <v-img
      class="hidden-sm-and-down"
      lazy-src="@/assets/img/mape/svg/logo.svg"
      max-height="80"
      max-width="170"
      src="@/assets/img/mape/svg/logo.svg"
      contain
    ></v-img>

    <v-dialog class="hidden-md-and-up" v-model="mobileMenu" fullscreen hide-overlay transition="dialog-bottom-transition">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="hidden-md-and-up" @click="mobileMenu = true" v-bind="attrs" v-on="on" color="primary" large icon>
          <v-icon>mdi-reorder-horizontal</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-toolbar dark color="primary" flat>
          <v-btn icon dark @click="mobileMenu = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <!-- <v-toolbar-title>Settings</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="dialog = false"> Save </v-btn>
          </v-toolbar-items> -->
        </v-toolbar>

        <v-list nav>
          <v-list-item-group>
            <v-list-item
              @click="
                $vuetify.goTo('#about-us');
                mobileMenu = false;
              "
              link
            >
              <v-list-item-title class="montserrat-font">{{ $t("app.aboutUs") }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="
                $vuetify.goTo('#platform');
                mobileMenu = false;
              "
              link
            >
              <v-list-item-title class="montserrat-font">{{ $t("app.platform") }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="
                $vuetify.goTo('#contact-us');
                mobileMenu = false;
              "
              link
            >
              <v-list-item-title class="montserrat-font">{{ $t("app.contactUs") }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-dialog>

    <v-spacer></v-spacer>
    <v-sheet class="hidden-sm-and-down" color="transparent">
      <v-spacer></v-spacer>
      <v-btn class="text-h6" @click="$vuetify.goTo('#about-us')" :color="dark ? '#FFF' : 'primary'" x-large text>
        {{ $t("app.aboutUs") }}
      </v-btn>
      <v-btn class="text-h6" @click="$vuetify.goTo('#platform')" :color="dark ? '#FFF' : 'primary'" x-large text>
        {{ $t("app.platform") }}
      </v-btn>
      <v-btn class="text-h6" @click="$vuetify.goTo('#contact-us')" :color="dark ? '#FFF' : 'primary'" x-large text>
        {{ $t("app.contactUs") }}
      </v-btn>
    </v-sheet>

    <v-img
      class="hidden-md-and-up"
      lazy-src="@/assets/img/mape/svg/logo.svg"
      max-height="80"
      max-width="170"
      src="@/assets/img/mape/svg/logo.svg"
      contain
    ></v-img>
    <v-spacer class="d-md-none"></v-spacer>
    <!-- <v-btn class="hidden-md-and-up" to="/login" color="primary" x-large icon>
      <v-icon size="36">mdi-account-circle-outline</v-icon>
    </v-btn> -->
    <v-btn class="hidden-sm-and-down" to="/login" :color="dark ? '#FFF' : 'primary'" x-large icon><v-icon size="36">mdi-account-circle-outline</v-icon></v-btn>

    <!-- <v-navigation-drawer v-model="mobileMenu" temporary app>
      <v-list nav>
        <v-list-item-group active-class="deep-purple--text text--accent-4">
          <v-list-item to="/" link>
            <v-list-item-title>{{ $t("app.aboutUs") }}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/platform" link>
            <v-list-item-title>{{ $t("app.platform") }}</v-list-item-title>
          </v-list-item>
          <v-list-item to="/contactUs" link>
            <v-list-item-title>{{ $t("app.contactUs") }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer> -->
  </v-app-bar>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "HeaderView",
  props: {
    scrollTarget: {
      type: String,
      required: false,
      default: () => {
        return null;
      },
    },
  },
  data: () => ({
    dark: true,
    darColor: "transparent",
    mobileMenu: false,
    // menuGroup: null,
  }),
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    ...mapActions("user", {
      logout: "logout",
    }),
    quit() {
      this.logout();
    },
    onScroll(event) {
      const scrollHeight = event.target.documentElement.scrollTop || event.target.body.scrollTop;
      if (scrollHeight >= 5) {
        this.darColor = "white";
        this.dark = false;
      } else {
        this.darColor = "transparent";
        this.dark = true;
      }
    },
  },
};
</script>
<style scoped>
.montserrat-font {
  font-family: "montserrat", "Roboto", serif !important;
}
</style>
