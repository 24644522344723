export default {
  /*
   * 格式化为千分位
   */
  thousandNum(num) {
    if (!this.isNumber(num)) {
      return num;
    }

    // 将数字转换为字符串
    let numStr = num.toString();
    // 判断是否存在小数点
    let decimalIndex = numStr.indexOf(".");
    let integerPart = numStr;
    let decimalPart = "";
    if (decimalIndex >= 0) {
      integerPart = numStr.slice(0, decimalIndex);
      decimalPart = numStr.slice(decimalIndex);
    }
    // 每三位数字添加逗号
    let thousandSeparatedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // 返回添加了千分位的字符串
    return thousandSeparatedIntegerPart + decimalPart;
  },

  /*
   * 格式化为千分位，不保留小数，例如#,##0
   */
  format2ThousandNum(num) {
    if (!this.isNumber(num)) {
      return num;
    }

    var intPart = Math.round(Math.abs(num));
    if (intPart < 1000) {
      return num < 0 ? "-" + intPart.toString() : intPart.toString();
    }
    var str = intPart.toString();
    // 使用正则表达式添加千位分隔符
    return (num < 0 ? "-" : "") + str.replace(/(\d)(?=(\d{3})+$)/g, "$1,");
  },
  /*
   * 格式化为千分位，保留2位小数，例如#,##0.00
   */
  format2ThousandNum2(num) {
    if (!this.isNumber(num)) {
      return num;
    }

    var intPart = Math.abs(num);
    if (intPart < 1000) {
      return num.toFixed(2).toString();
    } else {
      return num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },
  /*
   * 格式化为千分位，根据实际位数进行保留，最多保留2位小数，结果可能#,##0，#,##0.0, #,##0.00
   */
  format2ThousandNum3(num) {
    if (!this.isNumber(num)) {
      return num;
    }

    var formattedNum = num.toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });

    return formattedNum;
  },
  format2PerNum1(num) {
    if (!this.isNumber(num)) {
      return num;
    }

    return num.toFixed(2).toString();
  },
  isNegative(value) {
    if (!this.isNumber(value)) {
      return false;
    }

    return parseFloat(value) < 0;
  },
  isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  },
};
