import ToastBox from "./Toast";

const Toast = {};

Toast.install = (Vue) => {
  const ToastBoxContructor = Vue.extend(ToastBox);
  const instance = new ToastBoxContructor();
  instance.$mount(document.createElement("div"));
  document.body.appendChild(instance.$el);

  const fire = (type, message, solution, duration) => {
    instance.message = message;
    instance.solution = solution;
    instance.show = true;
    instance.color = type;
    instance.duration = duration;
    // setTimeout(() => {
    //   instance.show = false
    // }, duration)
  };

  Vue.prototype.$toast = {};
  Vue.prototype.$toast.success = (message, solution = "", duration = 3000) => {
    fire("#4CAF50", message, solution, duration);
  };

  Vue.prototype.$toast.info = (message, solution = "", duration = 3000) => {
    fire("#2196F3", message, solution, duration);
  };

  Vue.prototype.$toast.error = (message, solution, duration = 3000) => {
    fire("#F44336", message, solution, duration);
  };

  Vue.prototype.$toast.warning = (message, solution, duration = 3000) => {
    fire("#FB8C00", message, solution, duration);
  };
};

export default Toast;
