import vuetify from "@/plugins/vuetify";

export default {
  // 统一控制报表 loading 的效果
  buildLoadingOpts() {
    return {
      text: "Loading... Please wait",
      color: textColor(), // 旋转动画的颜色
      textColor: textColor(),
      maskColor: maskColor(0.8),
      zlevel: 1,
      fontSize: 20,
      fontWeight: "800",
      fontFamily: "nunitosans",
    };
  },

  // 统一控制报表 no data 的效果
  buildNoDataOpts() {
    return {
      text: "No data available",
      textColor: textColor(),
      maskColor: maskColor(1), // 完全不透明
      zlevel: 1,
      showSpinner: false, // 是否显示旋转动画（spinner）
      fontSize: 20,
      fontWeight: "800",
      fontFamily: "nunitosans",
    };
  },
};

function textColor() {
  if (vuetify.framework.theme.dark) {
    return "rgba(255, 255, 255, 0.6)";
  } else {
    return "rgba(0, 0, 0, 0.5)";
  }
}

function maskColor(alpha) {
  if (vuetify.framework.theme.dark) {
    return "rgba(30, 30, 30, " + alpha + ")";
  } else {
    return "rgba(255, 255, 255, " + alpha + ")";
  }
}
