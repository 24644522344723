<template>
  <v-row id="about-us" class="d-flex align-center justify-center pt-16" no-gutters>
    <v-col cols="12" md="6" class="d-none d-md-block">
      <div class="text-center"><img width="70%" src="@/assets/img/mape/png/thinking.png" /></div>
    </v-col>
    <v-col cols="12" md="6">
      <div class="px-4 px-md-10" style="position: relative">
        <div style="position: absolute; top: -50px; right: 10px; z-index: 0">
          <v-img width="200" src="@/assets/img/mape/png/bananer-right.png" contain></v-img>
        </div>

        <div style="position: relative; z-index: 1">
          <p class="montserrat-font text-h4 text-sm-h3 text-md-h2 font-weight-bold title-color">About Us</p>

          <p class="grey--text lighten-2">
            With 60+ years of joint experience in building award winning analytical solutions, we are specialised and experienced in delivering marketing
            performance solutions to our clients. With our international locations – Thailand, Hong Kong, China and the United Kingdom – we are ideally suited
            to bringing you knowledge and best practices from both the Asian region and internationally.
          </p>

          <p class="grey--text lighten-2">
            We started marketingApe to answer the questions all marketers want to have answered, but that require complex analytics, or other skills that a
            marketing team may not have. Our platform provides a means for marketers to get the business insights they need, without the need to build a team to
            do this themselves.
          </p>
        </div>

        <div class="d-none d-md-block my-6 text-left">
          <!-- <v-btn width="200" elevation="2" @click="$vuetify.goTo('#contact-us')" large rounded dark>LEARN MORE </v-btn> -->
        </div>
      </div>
    </v-col>
    <v-col cols="12" class="mt-4 d-md-none">
      <div class="text-center"><img width="95%" src="@/assets/img/mape/png/thinking.png" /></div>
    </v-col>
    <v-col cols="12" class="d-md-none">
      <div class="my-6 text-center">
        <v-btn width="200" elevation="2" @click="$vuetify.goTo('#contact-us')" large rounded dark>LEARN MORE </v-btn>
      </div>
    </v-col>
  </v-row>
</template>
<script>
export default {};
</script>
<style scoped>
.montserrat-font {
  font-family: "montserrat", "Roboto", serif !important;
}
.title-color {
  color: rgb(0, 0, 0, 0.6) !important;
}
</style>
