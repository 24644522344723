import http from "@/utils/http/index.js";

export default {
  add: (customerIntentionAdd) => {
    return http.$p("/customer/intention/add", customerIntentionAdd);
  },
  //site-secret
  siteSecret: () => {
    return http.$p("/customer/intention/site-secret");
  },
};
