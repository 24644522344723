import http from "@/utils/http/index.js";

export default {
  tenantPage: (param) => {
    return http.$p("/tenant/page", param);
  },
  tenantDetail: (param) => {
    return http.$p("/tenant/detail", param);
  },
  findSysUser: (param) => {
    return http.$p("/tenant/find-sys-user", param);
  },
  tenantAdd: (param) => {
    return http.$p("/tenant/add", param);
  },
  tenantModify: (param) => {
    return http.$p("/tenant/modify", param);
  },
  tenantChgStat: (param) => {
    return http.$p("/tenant/chg-stat", param);
  },
  tenantDelete: (param) => {
    return http.$p("/tenant/delete", param);
  },
  tenantListPerms: (param) => {
    return http.$p("/tenant/list-perms", param);
  },
  tenantBindPerms: (param) => {
    return http.$p("/tenant/bind-perms", param);
  },

  permsTree: () => {
    return http.$p("/permission/tree");
  },
  uploadLogo: (param) => {
    var headers = {
      "Content-Type": "multipart/form-data",
    };
    return http.$p("/logo/upload-images", param, headers);
  },
  tenantList: (param) => {
    return http.$p("/tenant/list", param);
  },
};
