import http from "@/utils/http/index.js";

export default {
  operatorPage: (param) => {
    return new Promise((resolve, reject) => {
      http
        .$p("/sys-operator/page", param)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  operatorDetail: (param) => {
    return new Promise((resolve, reject) => {
      http
        .$p("/sys-operator/detail", param)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  operatorAdd: (param) => {
    return new Promise((resolve, reject) => {
      http
        .$p("/sys-operator/add", param)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  operatorModInfo: (param) => {
    return new Promise((resolve, reject) => {
      http
        .$p("/sys-operator/mod-info", param)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  operatorChgStat: (param) => {
    return new Promise((resolve, reject) => {
      http
        .$p("/sys-operator/chg-stat", param)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  operatorRstPw: (param) => {
    return new Promise((resolve, reject) => {
      http
        .$p("/sys-operator/rst-pw", param)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  operatorDelete: (param) => {
    return new Promise((resolve, reject) => {
      http
        .$p("/sys-operator/delete", param)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
