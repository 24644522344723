import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/mape/home/HomeView.vue";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { ignoreAuth: true },
  },
  {
    path: "/error",
    name: "error",
    component: () => import("../views/mape/Error.vue"),
    meta: { ignoreAuth: true },
  },
  {
    path: "/login",
    name: "login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ "../views/mape/LoginView.vue"),
    meta: { ignoreAuth: true },
  },
  {
    path: "/register",
    name: "register",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "register" */ "../views/mape/RegisterView.vue"),
    meta: { ignoreAuth: true },
  },
  {
    path: "/print",
    name: "platformPrint",
    component: () => import("../views/mape/PlatformPrint.vue"),
    children: [
      {
        path: "/print/businessInsights",
        name: "BusinessInsightsPrint",
        component: () => import("../views/mape/reports/business/BusinessInsightsPrint.vue"),
      },
      {
        path: "/print/businessInsightsSimeDarby",
        name: "BusinessInsightsSimeDarbyPrint",
        component: () => import("../views/mape/reports/businessSimeDarby/BusinessInsightsPrint.vue"),
      },
      {
        path: "/print/summarycontributions",
        name: "SummaryContributionsPrint",
        component: () => import("../views/mape/reports/overview/SummaryContributionsPrint.vue"),
      },
      {
        path: "/print/returnoninvestment",
        name: "ReturnOnInvestmentPrint",
        component: () => import("../views/mape/reports/overview/ReturnOnInvestmentPrint.vue"),
      },
      {
        path: "/print/profitandlossovertime",
        name: "ProfitAndLossOvertimePrint",
        component: () => import("../views/mape/reports/overview/ProfitAndLossOvertimePrint.vue"),
      },
      //print model
      {
        path: "/print/modelfitreport",
        name: "ModelFitAndStatisticsPrint",
        component: () => import("../views/mape/reports/model/ModelFitAndStatisticsPrint.vue"),
      },
      {
        path: "/print/decompositionanalysisreport",
        name: "DecompositionAnalysisPrint",
        component: () => import("../views/mape/reports/model/DecompositionAnalysisPrint.vue"),
      },
      {
        path: "/print/roiovertime",
        name: "ROIOverTimePrint",
        component: () => import("../views/mape/reports/model/ROIOverTimePrint.vue"),
      },
      {
        path: "/print/responsecurves",
        name: "ResponseCurvesPrint",
        component: () => import("../views/mape/reports/model/ResponseCurvesPrint.vue"),
      },

      //print monthly
      {
        path: "/print/validationofmodelfitandstatistics",
        name: "ValidationOfModelFitAndStatisticsPrint",
        component: () => import("../views/mape/reports/monthly/ValidationOfModelFitAndStatisticsPrint.vue"),
      },
      {
        path: "/print/recentdecompositionanalysis",
        name: "RecentDecompositionAnalysisPrint",
        component: () => import("../views/mape/reports/monthly/RecentDecompositionAnalysisPrint.vue"),
      },
      {
        path: "/print/recentsummarycontributions",
        name: "RecentSummaryContributionsPrint",
        component: () => import("../views/mape/reports/monthly/RecentSummaryContributionsPrint.vue"),
      },
      {
        path: "/print/postmodelroiovertime",
        name: "PostModelROIOverTimePrint",
        component: () => import("../views/mape/reports/monthly/PostModelROIOverTimePrint.vue"),
      },
      {
        path: "/print/postmodelprofitandlossovertime",
        name: "PostModelProfitAndLossOverTimePrint",
        component: () => import("../views/mape/reports/monthly/PostModelProfitAndLossOverTimePrint.vue"),
      },
    ],
  },
  {
    path: "/platform",
    name: "platform",
    component: () => import("../views/mape/Platform.vue"),
    children: [
      {
        path: "/businessInsights",
        name: "businessInsights",
        component: () => import("../views/mape/reports/business/BusinessInsights.vue"),
      },
      {
        path: "/businessInsightsSimeDarby",
        name: "businessInsightsSimeDarby",
        component: () => import("../views/mape/reports/businessSimeDarby/BusinessInsights.vue"),
      },
      // {
      //   path: "/modelaccuracyreview",
      //   name: "modelaccuracyreview",
      //   component: () => import("../views/mape/reports/business/BusinessInsights.vue"),
      // },
      // {
      //   path: "/contributiontoperfomanceanalysis",
      //   name: "contributiontoperfomanceanalysis",
      //   component:  () => import("../views/mape/reports/business/ContributionPerformanceAnalysis.vue"),
      // },
      // {
      //   path: "/overallfinancialreview",
      //   name: "overallfinancialreview",
      //   component:  () => import("../views/mape/reports/business/OverallFinancialReview.vue"),
      // },
      // {
      //   path: "/recommendations",
      //   name: "recommendations",
      //   component:  () => import("../views/mape/reports/business/Recommendations.vue"),
      // },

      //overview
      {
        path: "/summarycontributionsreport",
        name: "summarycontributionsreport",
        component: () => import("../views/mape/reports/overview/SummaryContributions.vue"),
      },
      {
        path: "/returnoninvestmentreport",
        name: "returnoninvestmentreport",
        component: () => import("../views/mape/reports/overview/ReturnOnInvestment.vue"),
      },
      {
        path: "/profitandlossovertime",
        name: "profitandlossovertime",
        component: () => import("../views/mape/reports/overview/ProfitAndLossOvertime.vue"),
      },
      //model
      {
        path: "/modelfitreport",
        name: "modelfitreport",
        component: () => import("../views/mape/reports/model/ModelFitAndStatistics.vue"),
      },
      {
        path: "/decompositionanalysisreport",
        name: "decompositionanalysisreport",
        component: () => import("../views/mape/reports/model/DecompositionAnalysis.vue"),
      },
      {
        path: "/roiovertime",
        name: "roiovertime",
        component: () => import("../views/mape/reports/model/ROIOverTime.vue"),
      },
      {
        path: "/responsecurves",
        name: "responsecurves",
        component: () => import("../views/mape/reports/model/ResponseCurves.vue"),
      },
      //monthly
      {
        path: "/validationofmodelfitandstatistics",
        name: "validationofmodelfitandstatistics",
        component: () => import("../views/mape/reports/monthly/ValidationOfModelFitAndStatistics.vue"),
      },
      {
        path: "/recentdecompositionanalysis",
        name: "recentdecompositionanalysis",
        component: () => import("../views/mape/reports/monthly/RecentDecompositionAnalysis.vue"),
      },
      {
        path: "/recentsummarycontributions",
        name: "recentsummarycontributions",
        component: () => import("../views/mape/reports/monthly/RecentSummaryContributions.vue"),
      },
      {
        path: "/postmodelroiovertime",
        name: "postmodelroiovertime",
        component: () => import("../views/mape/reports/monthly/PostModelROIOverTime.vue"),
      },
      {
        path: "/postmodelprofitandlossovertime",
        name: "postmodelprofitandlossovertime",
        component: () => import("../views/mape/reports/monthly/PostModelProfitAndLossOverTime.vue"),
      },
      // performance
      {
        path: "/performanceReport1",
        name: "performanceReport1",
        component: () => import("../views/mape/reports/performance/PerformanceReport1.vue"),
      },
      {
        path: "/performanceReport2",
        name: "performanceReport2",
        component: () => import("../views/mape/reports/performance/PerformanceReport2.vue"),
      },
      {
        path: "/performanceReport3",
        name: "performanceReport3",
        component: () => import("../views/mape/reports/performance/PerformanceReport3.vue"),
      },
      {
        path: "/performanceReport",
        name: "performanceReport",
        component: () => import("../views/mape/reports/performance/PerformanceReport.vue"),
      },
      {
        path: "/data/views",
        name: "views",
        component: () => import("../views/mape/data/Views.vue"),
      },
      {
        path: "/data/connectors",
        name: "connectors",
        component: () => import("../views/mape/data/connect/Connectors.vue"),
        children: [
          {
            path: "addConnect",
            name: "addConnect",
            component: () => import("../views/mape/data/connect/AddConnect.vue"),
          },
          {
            path: "connectList",
            name: "connectList",
            component: () => import("../views/mape/data/connect/ConnectList.vue"),
          },
        ],
      },
      // data management
      {
        path: "/data/management/upload",
        name: "dmUpload",
        component: () => import("../views/mape/datamanagement/Upload.vue"),
      },
      {
        path: "/data/management/dashboard",
        name: "dmDashboard",
        component: () => import("../views/mape/datamanagement/Dashboard.vue"),
      },
      {
        path: "/data/management/explore",
        name: "dmExplore",
        component: () => import("../views/mape/datamanagement/Explore.vue"),
      },
      {
        path: "/data/management/profile",
        name: "dmProfile",
        component: () => import("../views/mape/datamanagement/Profile.vue"),
      },
      {
        path: "/data/management/validation",
        name: "dmValidation",
        component: () => import("../views/mape/datamanagement/Validation2.vue"),
      },
      {
        path: "/system/user-mgmt",
        name: "userMgmt",
        component: () => import("../views/mape/system/UserMgmt.vue"),
      },
      {
        path: "/system/account-mgmt",
        name: "accountMgmt",
        component: () => import("../views/mape/system/AccountMgmt.vue"),
      },
      // landing page
      {
        path: "/landing-page/subscription",
        name: "subscription",
        component: () => import("../views/mape/landing/Subscription.vue"),
      },
      {
        path: "/landing-page/subscription/project/:type",
        name: "subscriptionProject",
        component: () => import("../views/mape/landing/SubscriptionProject.vue"),
      },
    ],
  },
  {
    path: "/operation/login",
    name: "oprLogin",
    component: () => import("../views/mapeoperation/OprLoginView.vue"),
    meta: { ignoreAuth: true },
  },
  {
    path: "/operation/platform",
    name: "oprPlatform",
    component: () => import("../views/mapeoperation/OprPlatform.vue"),
    meta: {
      oprPer: true, // Operational Permission 运营权限
    },
    children: [
      {
        path: "/operation/sys-user",
        name: "sysUser",
        component: () => import("../views/mapeoperation/SysUser.vue"),
      },
      {
        path: "/operation/tenant",
        name: "tenant",
        component: () => import("../views/mapeoperation/Tenant.vue"),
      },
      {
        path: "/operation/sys-operator",
        name: "sysOperator",
        component: () => import("../views/mapeoperation/SysOperator.vue"),
      },
      //etl
      {
        path: "/operation/etl",
        name: "etl",
        component: () => import("../views/mapeoperation/ETL.vue"),
      },
      {
        path: "/operation/dataset-import",
        name: "dataSetImport",
        component: () => import("../views/mapeoperation/DataSetImport.vue"),
      },
      {
        path: "/operation/data/import",
        name: "import",
        component: () => import("../views/mapeoperation/Import.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    // return 期望滚动到哪个的位置
    if (to.hash) {
      document.querySelector(to.hash).scrollIntoView(true);
      // return {
      //   selector: to.hash,
      //   behavior: "smooth",
      // };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.oprPer)) {
    // 需要是operator才能访问的路由
    if (!isOperator()) {
      // 如果不是
      // 先记录当前访问路径
      store.dispatch("user/updateLastVisitFullPath", to.fullPath);
      // 跳转到operator登录页
      next("/operation/login");
      return;
    }
  }

  if (to.matched.some((record) => !record.meta.ignoreAuth)) {
    // 需要登录才能访问的路由
    if (!isLoggedIn()) {
      // 如果未登录
      // 先记录当前访问路径
      store.dispatch("user/updateLastVisitFullPath", to.fullPath);
      // 跳转到登录页
      next("/login");
      return;
    }
  }

  // 放行
  next();
});

function isLoggedIn() {
  // 判断用户是否已登录的逻辑
  let user = store.state.user;
  return user && user.info && user.info.tokenValue;
}

function isOperator() {
  // 判断用户是否是Operator
  let user = store.state.user;
  return user && user.info && user.info.roles && user.info.roles.length > 0;
}

export default router;
