<template>
  <v-dialog v-model="show" :width="mdialogWidth" max-width="500" hide-overlay persistent>
    <v-card>
      <v-card-text class="d-flex justify-center py-2">
        <v-avatar :color="color">
          <v-icon v-if="color === 'warning'" color="white" size="32">mdi-alert-box-outline</v-icon>
          <v-icon v-else-if="color === 'info'" color="white" size="32"> mdi-information-outline</v-icon>
          <v-icon v-else-if="color === 'error'" color="white" size="32"> mdi-close-circle-outline</v-icon>
          <v-icon v-else color="white" size="32">mdi-checkbox-marked-circle</v-icon>
        </v-avatar>
      </v-card-text>
      <v-card-title class="d-flex justify-center white--text text-h5 pb-6" :class="color">{{ title }}</v-card-title>
      <v-card-subtitle class="d-flex justify-center white--text" :class="color">{{ subtitle }}</v-card-subtitle>
      <v-card-actions class="d-flex justify-end">
        <v-btn color="primary" text @click="confirm()">I GOT IT</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { VDialog, VCard, VBtn, VAvatar, VIcon } from "vuetify/lib";
export default {
  name: "message",
  components: { VDialog, VCard, VBtn, VAvatar, VIcon },
  data() {
    return {
      show: false,
      title: "",
      subtitle: "",
      color: "",
    };
  },
  computed: {
    mdialogWidth() {
      return this.$vuetify.breakpoint.mobile ? "80%" : "40%";
    },
  },
};
</script>
<style scoped></style>
