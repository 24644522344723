import axios from "axios";
import router from "@/router";
import store from "@/store";
import constant from "@/utils/constant/constant.js";

const baseUrl = process.env.NODE_ENV === "development" ? window.default.baseUrl.dev : window.default.baseUrl.pro;

axios.defaults.baseURL = baseUrl;
axios.defaults.timeout = 30000;
axios.defaults.headers.common["Authorization"] = "AUTH_TOKEN";
axios.defaults.headers.post["Content-Type"] = "application/json";

// 添加请求拦截器
axios.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
axios.interceptors.response.use(
  function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    return response;
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);
export default {
  $p: (url, param, cusHeaders) => {
    param = param || {};

    // 统一获取token信息
    const tokenName = store.state.user.info.tokenName || "";
    const tokenValue = store.state.user.info.tokenValue;
    const tenantInfo = store.state.user.tenantInfo;

    var headers = {};
    if (cusHeaders) {
      headers = cusHeaders;
    } else {
      headers = {
        "content-type": "application/json",
      };
    }
    // 后端国际化暂定英文
    headers["Accept-Language"] = "en_US";

    if (tokenName !== "") {
      headers[tokenName] = tokenValue;
    }
    if (tenantInfo && tenantInfo.tenantKey) {
      headers["tk"] = tenantInfo.tenantKey;
    }
    return new Promise((resolve, reject) => {
      axios
        .post(url, param, { headers })
        .then((res) => {
          // http 状态码
          const status = res.status;
          if (status !== 200) {
            console.error(res);
            // 这里应该路由到 Error.vue 页面去比较好
            router.push({ path: "/error" });
            reject(res.statusText);
            return;
          }

          let resData = res.data;
          // 未登录或者已失效
          if (resData.code === "WUAU-4010") {
            // 记录当前访问路径
            store.dispatch("user/updateLastVisitFullPath", router.currentRoute.fullPath);

            // 清除用户信息
            store.dispatch("user/logout");

            router.push({ path: "/login" }).catch((err) => console.error(err));

            return;
          } else if (resData.code === "WUAU-4011") {
            // 没权限，跳转到主页
            router.push({ path: constant.landingPath }).catch((err) => console.error(err));

            return;
          }

          resolve(resData);
        })
        .catch((err) => {
          console.error(err);

          if (!(err.code === "ECONNABORTED" && err.message.indexOf("timeout") != -1) && err.code != "ERR_NETWORK") {
            // 这里应该路由到 Error.vue 页面去比较好
            router.push({ path: "/error" });
          }
          reject(err);
        });
    });
  },
  $g: (url) => {
    return axios.get(url);
  },
};
